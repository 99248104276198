<template>
  <div class="con-cah">
    <p style="color: #00A2FF;font-weight: 400;margin-bottom: 0px;text-align: center">{{ title }}</p>
    <chart :options="pie" :init-options="initOptions" ref="pie" autoresize/>
  </div>
</template>
<script>
import ECharts from '@/components/echarts/ECharts.vue'

export default {
  components: {
    chart: ECharts
  },
  props: {

    data: {
      type: Array,
      default () {
        return []
      }
    },
    colorList: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  },
  watch: {
    data (newVal, oldVue) {
      this.init()
    }
  },
  data () {
    return {
      initOptions: {
        renderer: 'canvas'
      },
      pie: {

        color: ['#0691EA', '#ED7F26', '#90EB89', '#F7B74B', '#F0702E'],
        tooltip: { // 鼠标悬停提示
          show: true,
          trigger: 'item'
        },
        legend: {
          // orient: "vertical",
          left: 'center',
          textStyle: {
            color: '#fff'
          },
          icon: 'circle',
          itemWidth: 8, // 设置宽度
          itemHeight: 8, // 设置高度
          data: []
        },
        series: [
          {
            name: this.title,
            type: 'pie',
            radius: '50%',
            center: ['50%', '50%'],
            labelLine: { // 设置指示线的长度
              normal: {
                length: 12,
                length2: 8
              }
            },
            // roseType: 'angle',// 南丁格尔图
            label: {
              normal: {
                show: true, // 显示数据
                color: '#fff',
                alignTo: 'labelLine',
                width: 100,
                // position: "inner", //隐藏标识线
                // position: 'inside', // 此处将展示的文字在内部展示,
                textStyle: {
                  fontWeight: 300,
                  fontSize: 12 // 文字的字体大小
                }
              }
            },
            data: [],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  methods: {
    init () {
      this.pie.series[0].data = this.data
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style scoped>
.con-cah {
  width: 100%;
  height: 100%;
}
</style>
